export enum TranslationKeys {
  SEARCH_ERROR = 'SEARCH_ERROR',
  DESIRED_PLAN_UNAVAILABLE = 'DESIRED_PLAN_UNAVAILABLE',
  USER_PROTECTED = 'USER_PROTECTED',
  USER_OWN_PLAN = 'USER_OWN_PLAN',
  USER_EXISTS = 'USER_EXISTS',
  USER_IS_NOT_ACTIVATED = 'USER_IS_NOT_ACTIVATED',
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
  INVALID_MFA_CODE = 'INVALID_MFA_CODE',
  WRONG_OTC_CODE = 'WRONG_OTC_CODE',
}
