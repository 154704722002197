import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { PanicStatus } from 'types';

export const primaryColor = orange;
export const secondaryColor = blue;
export const errorColor = red;

export const black = '#000';
export const darkGray = '#111212';
export const gray = '#6A6A6A';
export const lightGray = '#AEAEAE';
// export const backgroundColor = '#F6F6F6';
export const bgColorLight = '#F6F6F6';
export const darkPaper = '#2F3030';
export const white = '#FFFFFF';
export const borderColor = '#E9E9E9';


export const statusColors: Record<PanicStatus, string> = {
  assigned: '#D96A02',
  deassigned: '#D32F2F',
  pending: '#D32F2F',
  processed: '#F4BA09',
  done: '#F4BA09',
  accepted: '#066056',
  inplace: '#2690E0',
  extended: '#2690E0',
  cancel_request: '#ff9800',
  // placeholders since these statuses are not used in the panic-list section
  accepted_operator: 'transparent',
  canceled: 'transparent',
};


export const notificationTypes: Record<PanicStatus | 'race_lost', 'error' | 'warning' | 'default' | 'success' | 'info'> = {
  accepted_operator: 'default',
  assigned: 'default',
  deassigned: 'error',
  pending: 'error',
  canceled: 'success',
  processed: 'warning',
  done: 'warning',
  accepted: 'success',
  inplace: 'info',
  extended: 'info',
  race_lost: 'default',
  cancel_request: 'warning',
};


export const newLandingBlue = '#00BDD5';
export const newLandingDarkGray = '#2F3030';
export const gray1 = '#333333';
export const gray6 = '#F2F2F2';
export const gray3 = '#F5F5F5';
export const error = '#FB2424';
export const lightBlue = '#6AD8E6';
