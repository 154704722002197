import React, { useEffect, useState } from 'react';
import { Theme } from '@material-ui/core/styles';
import {
  Table,
  TablePagination,
  TableHead,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  makeStyles,
  Box,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

let timer: NodeJS.Timeout | null = null;
let searchTimer: NodeJS.Timeout | null = null;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto' as const,
  },
  cellRoot: {
    padding: '6px 10px',
  },
  headerCell: {
    color: '#AEAEAE',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: '8px 10px',
  },
  filteredCell: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  footerRoot: {
    '& td': {
      border: 'none',
    },
  },
  tableRow: {
    '& th:first-child': {
      paddingLeft: 0,
    },
    '& th:last-child': {
      paddingRight: 0,
    },
  },
}));



type GetRequestType = (p: {
  filters: {
    search: string;
    sort?: { [key: string]: string };
    pagination: { limit: number; offset: number };
    page: number;
  };
}) => void;

type Props = {
  columns?: Array<{
    Header: string;
    accessor: string | Function;
    id?: string;
    filterBy?: string;
    Filter?: React.FC<{
      filterValue: string;
      setFilter: Function;
    }>;
    style?: { [key: string]: any };
  }>;
  data?: object[];
  header?: React.ReactNode;
  body?: React.ReactNode;
  onRowClick?: Function;
  getRequest?: GetRequestType;
  showPagination?: boolean;
  rowsPerPage?: number;
  isAccentRowCondition?: (item: any) => boolean;
  totalItems?: number;
};

const CustomTable = ({
  columns = [],
  data = [],
  header,
  body,
  onRowClick,
  getRequest = () => {},
  showPagination = true,
  rowsPerPage = 20,
  isAccentRowCondition,
  totalItems = -1,
}: Props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [filters, handleFilters] = useState<{
    search: string;
    sort?: { [key: string]: string };
    [p: string]: any;
  }>({ search: '', role: '' });

  useEffect(() => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      const pagination = {
        limit: rowsPerPage,
        offset: (page || 0) * rowsPerPage,
      };
      getRequest({
        filters: {
          ...filters,
          pagination,
          page: 1
        },
      });
      setPage(0);
    }, 500);
  }, [filters]);

  useEffect(() => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      const pagination = {
        limit: rowsPerPage,
        offset: (page || 0) * rowsPerPage,
      };
      getRequest({
        filters: {
          ...filters,
          pagination,
          page: page + 1
        },
      });
    }, 500);
  }, [page]);

  const handleSort = (name?: string, value?: string) => {
    const key = name || 'created_at';
    handleFilters((state) => ({
      ...state,
      sort: value
        ? {
          [`filter[ORDER][${key}]`]: value,
          order: value,
          name: key,
        }
        : undefined,
    }));
  };

  const handleSearch = (key: string = 'search', value: string) => {
    handleFilters((state) => ({
      ...state,
      [key]: value,
    }));
    searchTimer && clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      setPage(0);
    }, 200);
  };
  const handleChangePage: React.ComponentProps<typeof TablePagination>['onChangePage'] = (event, newPage) => {
    setPage(newPage);
  };

  const filterValueHandler = (id?: number | string): string => filters[id ? id.toString() : 'search'];
  const setFilterHandler = (id?: number | string): any => handleSearch.bind(null, id ? id.toString() : undefined);
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.tableRow}>
          {header
            || (columns && (
              <>
                {columns.map(({ Header, style, Filter, filterBy, id }) => (
                  <TableCell key={Header} style={style} className={classes.headerCell}>
                    {Filter ? (
                      <Filter filterValue={filterValueHandler(id)} setFilter={setFilterHandler(id)} />
                    ) : (
                      <div
                        className={filterBy ? classes.filteredCell : ''}
                        style={style}
                        onClick={() => {
                          if (filterBy) {
                            const isAsc = filters.sort?.order === 'ASC';
                            const isDesc = filters.sort?.order === 'DESC';
                            const sortOrder = isAsc ? 'DESC' : 'ASC';
                            handleSort(id, isDesc ? undefined : sortOrder);
                          }
                        }}
                      >
                        {Header && <FormattedMessage id={Header} />}
                        {filterBy && filters.sort && filters.sort.name === (id || 'created_at') && (
                          <Box marginLeft="10px" component="span" marginTop="-5px" fontSize="24px">
                            {filters.sort?.order === 'ASC' ? <>&#8593;</> : <>&#8595;</>}
                          </Box>
                        )}
                      </div>
                    )}
                  </TableCell>
                ))}
              </>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {body
          || (data && (
            <>
              {data.map((item: { [key: string]: any }) => (
                <TableRow
                  className={classes.tableRow}
                  style={{ cursor: onRowClick ? 'pointer' : 'default', background: isAccentRowCondition?.(item) ? 'darkred' : '' }}
                  key={item.id}
                  onClick={() => onRowClick && onRowClick(item)}
                >
                  {columns.map(({ accessor, style }) => (
                    <TableCell
                      classes={{ root: classes.cellRoot }}
                      component="th"
                      scope="row"
                      key={`${accessor}-${item.id}`}
                      style={style}
                    >
                      {typeof accessor === 'string'
                        ? accessor.split('.').reduce((acc, key) => {
                          if (acc) {
                            return (acc as any)[key];
                          }
                          return item[key];
                        }, null)
                        : accessor(item)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ))}
      </TableBody>
      {showPagination && (
        <TableFooter classes={{ root: classes.footerRoot }}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              count={totalItems}
              page={page}
              labelDisplayedRows={({ from, to, count }) => (
                <>
                  {from} - {to}
                  {count > 0 ? ` | ${count}` : ''}
                </>
              )}
              SelectProps={{
                native: true,
              }}
              onChangePage={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default CustomTable;
