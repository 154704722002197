import React from 'react';
import Routes from './routes';
import { useSelector } from 'react-redux';
import { IAppState } from 'types';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from 'utils';
import messages from 'translations/messages';

const App = () => {
  const locale = useSelector(({ translation }: IAppState) => translation.locale);
  return (
    <IntlProvider locale={locale} messages={flattenMessages(messages[locale])}>
      <Routes  />
    </IntlProvider>
  )
};

export default App;
