import { makeStyles } from '@material-ui/core';
import { darkPaper, gray, lightGray, white } from '../../../constants/colors';

// container, title, cancelBtn, acceptBtn, iconBtn

const useStyles = makeStyles({
  tel: {
    color: gray,
    fontSize: 12,
  },
  info: {
    marginTop: 16,
    marginBottom: 50,
    color: darkPaper,
  },
  href: {
    textDecoration: 'none',
    color: 'inherit',
  },
  container: {
    maxWidth: 600,
    width: '100%',
    padding: '30px 18px',
    backgroundColor: 'white',
  },
  title: { fontWeight: 500, marginBottom: 16, color: darkPaper },
  cancelBtn: {
    marginRight: 8,
    border: `2px solid ${lightGray}`,
    color: lightGray,
    backgroundColor: white,
    '&:hover': {
      borderColor: lightGray,
      backgroundColor: lightGray,
      color: white,
    },
  },
  acceptBtn: {
    border: `2px solid ${darkPaper}`,
    color: darkPaper,
    backgroundColor: white,
    '&:hover': {
      boxShadow: `0 0 0 2px ${darkPaper}`,
      backgroundColor: lightGray,
      borderColor: 'transparent',
    },
  },
});

export default useStyles;
