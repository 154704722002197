import { createMuiTheme } from '@material-ui/core/styles';
import { errorColor, white, lightGray, gray, darkPaper, borderColor, gray3 } from './constants/colors';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: { main: white },
    secondary: { main: darkPaper },
    error: errorColor,
    background: {
      default: darkPaper,
      paper: darkPaper,
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontSize: '64px',
      lineHeight: '70px',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '48px',
      lineHeight: '55px',
    },
    h3: {
      fontSize: '36px',
      lineHeight: '44px',
    },
    h4: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    body1: {
      fontSize: '18px',
      lineHeight: '30px',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '23px',
    },
    subtitle1: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    caption: {
      fontSize: '10px',
      lineHeight: '16px',
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        'input[type="time"]::-webkit-calendar-picker-indicator': {
          filter: 'invert(100%)',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '36px',
        fontWeight: 'bold',
        minWidth: 75,
      },
      outlined: {
        height: 44,
        fontSize: '16px',
        lineHeight: '22px',
        borderWidth: '2px',
        borderColor: white,
        color: white,
        backgroundColor: darkPaper,
        padding: '8px 22px',
        '&:hover': {
          boxShadow: `0 0 0 2px ${white}`,
          backgroundColor: gray,
          borderColor: 'transparent',
        },
        '&$disabled': {
          boxShadow: 'none',
          backgroundColor: gray,
          borderColor: 'transparent',
        },
      },
      outlinedSecondary: {
        backgroundColor: 'transparent',
        color: lightGray,
        border: 'none',
        boxShadow: `0 0 0 2px ${lightGray}`,
        '&:hover': {
          backgroundColor: lightGray,
          color: white,
          boxShadow: `0 0 0 2px ${lightGray}`,
          border: 'none',
        },
      },
      outlinedPrimary: {
        backgroundColor: 'transparent',
      },
      sizeSmall: {
        fontSize: '14px',
        lineHeight: '18px',
        padding: '8px 16px',
        height: 32,
        minWidth: 75,
      },
    },
    MuiFormControl: {
      marginNormal: { marginTop: 0, marginBottom: 24 },
    },
    MuiFormLabel: {
      root: {
        '&$disabled': {
          color: '#888893',
        },
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: 4,
      },
      input: {
        backgroundColor: 'transparent',
        color: white,
        '&$disabled': {
          color: '#888893',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: gray,
        '&$focused': {
          // increase the specificity for the pseudo class
          color: gray,
        },
      },
    },
    MuiInputAdornment: {
      filled: {
        color: gray,
        position: 'absolute',
        background: 'transparent',
        marginTop: '0 !important',
        paddingTop: 17,
        paddingLeft: 12,
        height: '100%',
        maxHeight: '100%',
      },
    },
    MuiFilledInput: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: `0 0 0 100px ${gray3} inset`,
          WebkitTextFillColor: 'inherit',
        },
      },
      inputAdornedStart: {
        paddingLeft: 60,
      },
      adornedStart: {
        paddingLeft: 0,
      },
      root: {
        backgroundColor: 'transparent',
        border: `1px solid ${borderColor}`,
        transition: 'none',
        borderRadius: 4,
        color: darkPaper,
        // "& :-webkit-autofill": {
        //   WebkitBoxShadow: "0 0 0 100px #266798 inset",
        //   WebkitTextFillColor: "#fff",
        // },
        '&$focused': {
          borderColor: gray,
        },
      },
    },
  },
});

export default theme;
