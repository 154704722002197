import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { lightGray } from 'constants/colors';
import { MediaContent } from '../../../types';
import { useFile } from '../../../utils/get-file';

const useStyles = makeStyles({
  cardStyles: {
    marginBottom: '8px',
    padding: '8px 16px',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  },
  cardHeader: {
    '& p': {
      color: lightGray,
      fontWeight: 'bold',
    },
  },
  audioContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '8px',
  },
  audioItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  audioTitle: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  audioPlayer: {
    width: '100%',
  },
});

type Props = {
  comment: string;
  media: MediaContent[] | null
};

const ClientComment = ({ comment = '-', media }: Props) => {
  const { cardStyles, cardHeader, audioContainer, audioPlayer, audioItem, audioTitle } = useStyles();

  const textComments = media
    ?.filter((item) => item.type === 'text' && item.comment)
    .map((item) => item.comment) || [];

  const allComments = [comment, ...textComments].filter(Boolean).join('; ') || '-';

  const audioFiles = media?.filter((item) => item.type === 'audio') || []

  const getAudio = (id: string,) => useFile(id ? `v1/storage/file/private/${id}` : '');
  return (
    <Box className={cardStyles}>
      <Box mb={2} className={cardHeader}>
        <Typography variant="body2">
          <FormattedMessage id="common.client_comments" />
        </Typography>
      </Box>
      <Typography variant="body2">{allComments}</Typography>

      {audioFiles.length ? (
        <Box className={audioContainer}>
          {audioFiles.map((audio, index) => (
            <Box key={audio.id} className={audioItem}>
              <Typography className={audioTitle}>
                {audio.comment ? audio.comment : `Audio Message ${index + 1}`}
              </Typography>

              <audio controls className={audioPlayer} src={getAudio(audio.asset.uuid)}>
                Your browser does not support the audio element.
              </audio>
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};


export default ClientComment;
